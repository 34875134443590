<div class="lapro-notice-banner {{ type }} p-2 relative">
    <div class="flex gap-2">
        <div *ngIf="showIcon">
            <app-sms-spinner size="sm" *ngIf="type === 'announcement'"></app-sms-spinner>
            <mat-icon *ngIf="type === 'alert'">warning</mat-icon>
            <mat-icon *ngIf="type === 'info'">info</mat-icon>
            <mat-icon *ngIf="type === 'success'">check_circle</mat-icon>
            <mat-icon *ngIf="type === 'warn'">info</mat-icon>
        </div>
        <div>
            <ng-content></ng-content>
        </div>
    </div>
    <span class="absolute right-0 top-0" *ngIf="canDismiss">
        <button mat-icon-button (click)="closeNotice()"><mat-icon>close</mat-icon></button>
    </span>
</div>
